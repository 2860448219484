<template>
    <div class="main-content">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
      <div class="row">
        <div class="col-3">
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-body pt-5">
              <div>
                <h3>Generate Parking Voucher(s)</h3>
              </div>
              <form action="#" class="form-horizontal "  method="post" v-on:submit.prevent="saveVoucher">
               <div >
                  <div class="mb-3">
                    <label class="form-label" >Buying Options:</label> <br/>
                    <div >
                      <label>
                        <input name="buyingOption" v-model="voucher.buyingOption" type="radio" value="single" /> Single
                      </label>
                    </div>
                    <div >
                      <label>
                        <input name="buyingOption" v-model="voucher.buyingOption" type="radio" value="bulk" /> Bulk
                      </label>
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <div class="col-md-6">
                      <label class="form-label">
                        Outlet:
                      </label>
                      <select class="form-control" v-model="voucher.outletId">
                        <option :value="outlet" v-for="outlet in outlets">{{ outlet.name }}</option>
                      </select>
                    </div>
                    <div class="col-md-6" v-if="voucher.outletId">
                      <div v-if="voucher.outletId.parkingVoucherPaymentType === 'POSTPAID'">
                        <div class="mb-2">
                          <strong>Parking Voucher Type:</strong> <br/>
                          Postpaid
                        </div>
                        <label class="form-label">
                          Duration (Hours):
                        </label>
                        <div class="form-group">
                        <span v-if="voucher.outletId && voucher.outletId.maximumVoucherParkingHours">
                          {{voucher.outletId.maximumVoucherParkingHours}} hrs
                        </span>
                          <span v-else-if="voucher.outletId && !voucher.outletId.maximumVoucherParkingHours">
                          Hours for parking voucher not set.
                        </span>
                        </div>
                      </div>
                      <div v-else-if="voucher.outletId.parkingVoucherPaymentType === 'PREPAID' && voucher.outletId.prepaidVoucherParkingAmount">
                        <div class="mb-2">
                          <strong>Parking Voucher Type:</strong> <br/>
                          Prepaid
                        </div>

                        <label class="form-label">
                          Parking fee to pay:
                        </label>
                        <div class="form-group">
                          KES. {{ voucher.outletId.prepaidVoucherParkingAmount }}
                        </div>
                      </div>
                      <div v-else>
                        <strong>Parking payment type for vouchers not set.</strong>
                      </div>


                    </div>
                  </div>
                  <div v-if="voucher.buyingOption == 'single'">
                    <div class="mb-3" >
                      <label class="form-label" >Customer Type:</label> <br/>
                      <div >
                        <label>
                          <input name="existingCustomer" v-model="voucher.existingCustomer" type="radio" value="yes" /> Registered
                        </label>
                      </div>
                      <div >
                        <label>
                          <input name="existingCustomer" v-model="voucher.existingCustomer" type="radio" value="no" /> Unregistered
                        </label>
                      </div>
                    </div>

                    <div v-if="voucher.existingCustomer == 'yes'" >
                      <label class="form-label">
                        Search By Phone Number:
                      </label>
                      <div class="input-group mb-2">
                        <input v-model.trim="voucher.phoneNumber" type="text" class="form-control"  />
                        <div style="cursor: pointer;" class="input-group-append" @click="searchCustomer">
                          <span class="input-group-text" >Find</span>
                        </div>
                      </div>

                      <div v-if="customers.length > 0">
                        <table class="table table-bordered">
                          <tr>
                            <th>Select</th>
                            <th>Name</th>
                            <th>Phone number</th>
                          </tr>
                          <tr v-for="customer in customers">
                            <td> <input type="radio" :value="customer" v-model.trim="$v.voucher.customerId.$model"  name="selected_customers" /> </td>
                            <td> {{ customer.name }} </td>
                            <td> {{ customer.mobileNumber }} </td>
                          </tr>
                        </table>
                      </div>
                      <div class="text-danger" v-if="!$v.voucher.customerId.required && $v.voucher.customerId.$dirty">Please select customer to proceed</div>

                    </div>
                    <div class="mt-2" v-else>
                      <div class="form-group ">
                        <label class="form-label" :class="{ 'text-danger': $v.voucher.name.$error }">Name:</label> <br/>
                        <div >
                          <input  v-model.trim="$v.voucher.name.$model" class="form-control" type="text" />
                        </div>
                        <div class="text-danger" v-if="!$v.voucher.name.required && $v.voucher.name.$dirty">Please enter name of recipient</div>
                      </div>
                      <div class="form-group ">
                        <label class="form-label" :class="{ 'text-danger': $v.voucher.phoneNumber.$error }">Phone Number:</label> <br/>
                        <div >
                          <input  v-model.trim="$v.voucher.phoneNumber.$model" class="form-control" type="text" />
                        </div>
                        <div class="text-danger" v-if="!$v.voucher.phoneNumber.required && $v.voucher.phoneNumber.$dirty">Please phone number of recipient</div>
                      </div>
                      <div class="form-group ">
                        <label class="form-label" >Email Address:</label> <br/>
                        <div >
                          <input v-model.trim="voucher.emailAddress" class="form-control" type="text" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <label class="form-label">Voucher Recipient Data</label> <br/>
                    <input class="form-control" type="file"  ref="voucherRecipientData" @change="handleVoucherRecipientFileSelected"  /> <a  href="/VoucherRecipientsCorporateTemplate.xlsx">Download Sample File</a> <br/>
                    <div class="text-danger" v-if="!$v.voucher.voucherRecipients.required && $v.voucher.voucherRecipients.$dirty">Please upload voucher recipients file</div>
                    <div class="mt-2 mb-2">
                      <button v-if="voucherRecipientFile" type="button" class="btn btn-success" @click="saveVoucherRecipientsImportedFile">Upload File</button>
                    </div>

                    <div class="mb-2" v-if="voucher.voucherRecipients.length > 0" style="max-height: 400px;overflow-y: scroll;">
                      <table class="table table-bordered">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                        </tr>
                        <tr v-for="voucherRecipient in voucher.voucherRecipients">
                          <td>{{ voucherRecipient.name }}</td>
                          <td>{{ voucherRecipient.email }}</td>
                          <td>{{ voucherRecipient.phoneNumber }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                   <div class="form-group mt-2 mb-2">
                     <label class="form-label" >Purpose:</label> <br/>
                     <div >
                       <textarea class="form-control" rows="10" v-model="voucher.message"></textarea>
                     </div>
                   </div>
                </div>
                <div>
                  <button type="submit" class="btn btn-success float-right">Generate</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-3">
        </div>
      </div>
      <b-modal id="generate-voucher-preview" title="Voucher Review" @cancel="true" @ok="saveVoucher"   centered>
        <div >
          <div class="mb-3">
            <label class="form-label" >Customer Type:</label> <br/>
            <div v-if="voucher.existingCustomer == 'yes'">
              Registered
            </div>
            <div v-else>
              Unregistered
            </div>
          </div>

          <div v-if="voucher.existingCustomer == 'yes'" >

            <div v-if="customers.length > 0">
              <table class="table table-bordered">
                <tr>
                  <th>Name</th>
                  <th>Phone number</th>
                </tr>
                <tr v-for="customer in customers">
                  <td> {{ customer.name }} </td>
                  <td> {{ customer.mobileNumber }} </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="mt-2" v-else>
            <div class="form-group ">
              <label class="form-label" >Name:</label> <br/>
              <div v-if="voucher.name">
                {{ voucher.name }}
              </div>
            </div>
            <div class="form-group ">
              <label class="form-label">Phone Number:</label> <br/>
              <div v-if="voucher.phoneNumber">
                {{ voucher.phoneNumber }}
              </div>
            </div>
            <div class="form-group ">
              <label class="form-label" >Email Address:</label> <br/>
              <div v-if="voucher.emailAddress">
                {{ voucher.emailAddress }}
              </div>
            </div>
            <div class="form-group ">
              <label class="form-label" >Message:</label> <br/>
              <div v-if="voucher.message">
                {{ voucher.message }}
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="row">
              <div class="col-md-12">
                <hr/>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" >Voucher Type:</label> <br/>
            <div >
              {{ voucher.voucherType }}
            </div>
          </div>

          <div class="form-group " v-if="voucher.voucherType != 'outlet'">
            <label class="form-label"  >Redemption Options:</label> <br/>
            <div v-if="voucher.redemptionOption">
              Any Outlet
            </div>
            <div v-else>
              Specific
            </div>

            <div v-if="voucher.redemptionOption == 'specific'" >
              <label class="form-label" >
                Outlet:
              </label>
              <ul>
                <li v-for="redemptionOutlet in voucher.outlets">{{ redemptionOutlet.name }}</li>
              </ul>
            </div>
          </div>

          <div class="form-group" v-if="voucher.voucherType != 'outlet'">
            <label class="form-label" >Code Generation Option:</label> <br/>
            <div >
              {{ voucher.generateVoucherCode }}
            </div>
            <div v-if="voucher.generateVoucherCode == 'manual'" >
              <label class="form-label" >
                Voucher Code:
              </label>
              <div >
                {{ voucher.voucherCode }}
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="row">
              <div class="col-md-12">
                <hr/>
              </div>
            </div>
          </div>
          <div class="form-group ">
            <label class="form-label"  >Payment Status:</label> <br/>
            <div >
              {{ voucher.paymentStatus }}
            </div>
            <div v-if="voucher.paymentStatus == 'PAID'" >
              <label class="form-label" >
                Transaction ID:
              </label>
              <span v-if="voucher.transactionId">{{ voucher.transactionId }}</span>
            </div>
          </div>

          <div class="form-group ">
            <label class="form-label"  >Payment Option:</label> <br/>
            <div v-if="voucher.paymentOption">
              {{ voucher.paymentOption }}
            </div>

            <div v-if="voucher.paymentOption == 'MPESA'" >
              <label class="form-label" >
                Mpesa Phone Number:
              </label>
              <span v-if="voucher.mpesaPhoneNumber">{{ voucher.mpesaPhoneNumber }}</span>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" >
              Amount
            </label>
            <div >
              {{ voucher.amount }}
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" >
              Transacted By
            </label>
            <div >
              {{ voucher.transactedBy }}
            </div>
          </div>
        </div>
      </b-modal>
    </div>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    require('vue-toastr/src/vue-toastr.scss');
    import { mapGetters, mapActions } from "vuex";
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import vSelect from "vue-select";
    import Table from "@/views/table/bs-table/Table.vue";
    import { required,requiredIf} from 'vuelidate/lib/validators'
    import CodeInput from "vue-verification-code-input";

    export default {
        name: 'generate-parking-voucher',
        data: () => ({
          fileUpload:"",
            voucherOutletId:0,
            customers:[],
            transaction_status:"all",
            lang: {
                days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                placeholder: {
                    date: 'Select Date',
                    dateRange: 'Select Date Range'
                }
            },
            card_summary:{
              totalCardsValue:"15,000",
              totalRedeemed:"5000",
              expired:"10,000"
             },
            table_loading:true,
            tickets:"",
            total_tickets:0,
            total_tickets_today:0,
            total_tickets_this_week:0,
            approval_decline_comments:"",
            from_date:"all",
            to_date:"all",
            fromDateVoucherCode:"all",
            toDateVoucherCode:"all",
            search_phone_number:"",
            rowsPerPageItems: [50, 100, 150, 200],
            selected: [],
            users:[],
            columns:[{
              key: 'created',
              label: 'Date',
              sortable: false
            },"boughtBy","recipient","amount","balance","voucherCode","paymentMode",{
              key: 'giftStatus',
              label: 'Status',
              sortable: false
            },"expiryDate","Options"],
          outletVoucherColumns:[{
            key: 'created',
            label: 'Date',
            sortable: false
          },"voucherCode","outlet","amount","expiryDate", "status","Options"],
          outletVouchers:[],
          unutilisedVouchers:[],
            isLoading: false,
            fullPage: true,
            loading:false,
            voucherStatus:"all",
            vouchers: [
            ],
             outlets:[],
            csrf: "",

            lottery:"",
            pagination: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            offset: 4,
            paginationVoucherCodes: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            offsetVoucherCodes: 4,
            voucher:{
                voucherType:"sarit",
                existingCustomer:"yes",
                buyingOption:"single",
                name:"",
                phoneNumber:"",
                emailAddress:"",
                message:"",
                redemptionOption:"any",
                outlets:[],
                amount:"",
                voucherCode:"",
                generateVoucherCode:"system",
                mpesaPhoneNumber:"",
                paymentOption:"MPESA",
                customerId:"",
                voucherOutletId:"",
                outletVouchers:[],
                paymentStatus:"NOT PAID",
                transactionId:"",
                transactedBy:"",
                duration:0,
                outletId:0,
                expiryDate:"",
                voucherRecipients:[]
            },
            currentStep:"Step 1",
            customerVerificationCode:"",
            voucherCodeVerified: false,
            verificationCode:"",
            voucherRecipientFile: null
        }),
        validations: {
          voucher:{
            voucherType: {
              required: requiredIf(function () {
                return this.currentStep == 'Step 1'
              })
            },
            name:{
              required: requiredIf(function () {
                return this.voucher.existingCustomer == 'no' && this.currentStep == 'Step 1'
                    && this.voucher.buyingOption == 'single'
              })
            },
            phoneNumber:{
              required: requiredIf(function () {
                return this.voucher.existingCustomer == 'no' && this.currentStep == 'Step 1'
                    && this.voucher.buyingOption == 'single'
              })
            },
            voucherRecipients:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 1' && this.voucher.buyingOption == 'bulk'
              })
            },
            redemptionOption:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 2'
              })
            },

            outlets:{
              required: requiredIf(function () {
                return this.voucher.redemptionOption == 'specific' && this.currentStep == 'Step 2'
              })
            },
            amount:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 3'
              })
            },
            voucherCode:{
              required: requiredIf(function () {
                return this.voucher.generateVoucherCode == 'manual' && this.currentStep == 'Step 2'
              })
            },
            generateVoucherCode:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 2'
              })
            },
            mpesaPhoneNumber:{
              required: requiredIf(function () {
                return this.voucher.paymentOption == 'MPESA' && this.currentStep == 'Step 3'
              })
            },
            paymentOption:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 3'
              })
            },
            transactedBy:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 3'
              })
            },
            paymentStatus:{
              required: requiredIf(function () {
                return  this.currentStep == 'Step 3'
              })
            },
            transactionId:{
              required: requiredIf(function () {
                return this.voucher.paymentStatus == 'PAID' && this.currentStep == 'Step 3'
              })
            },
            customerId:{
              required: requiredIf(function () {
                return this.voucher.existingCustomer == 'yes' &&  this.currentStep == 'Step 1'
                    && this.voucher.buyingOption == 'single'
              })
            },
            voucherOutletId:{
              required: requiredIf(function () {
                return this.voucher.voucherType == 'outlet'  &&  this.currentStep == 'Step 2'
              })
            },
            outletVouchers:{
              required: requiredIf(function () {
                return this.voucher.voucherType == 'outlet' &&  this.currentStep == 'Step 2'
              })
            }
          }
        },
        computed:{
          role(){
            return localStorage.getItem("role");
          }
        },
        mounted() {
          if(this.role == "Merchant" || this.role == "Cashier"){
            this.getOutletByMerchant();
          } else {
            this.getOutletsData();
          }
        },
        methods:{
          ...mapActions(["getOutletsByUserId","importVoucherRecipients","getVouchers", "getOutlets","getVoucherStats","searchCustomerByPhoneNumber","getOutletVoucherCodes","importVoucherCodes","createParkingVoucher","sendVerificationCode"]),
          openVoucherPreview() {
            this.$bvModal.show('generate-voucher-preview');
          },
          getOutletByMerchant(){
            let self = this;

            this.isLoading = true
            this.getOutletsByUserId({userId: localStorage.getItem("userId")})
                .then(function (outlets) {

                  self.isLoading = false

                  self.outlets = outlets;

                  if(self.outlets.length > 0){
                    self.outlet = self.outlets[0];

                    self.getTransactionsData();
                    self.getMerchantLoyaltyStatsData();
                  }

                })
                .catch(function (error) {
                  if( typeof error.response.status != "undefined"){
                    if(error.response.status == 401){
                      location.replace("/login");
                    }

                  }
                })
          },
          saveVoucherRecipientsImportedFile(){
            if(this.voucherRecipientFile){
              let self = this;
              this.isLoading = true;

              let formData = new FormData();
              formData.append('excelFile', this.voucherRecipientFile);

              this.importVoucherRecipients(formData).then((response) => {
                self.isLoading = false;

                self.voucher.voucherRecipients = response.voucherRecipientsDTOS;
                self.voucher.amount = response.voucherRecipientsDTOS.reduce((sum, item) => sum + item.amount, 0)

              })
                  .catch(error => {

                    self.isLoading = false;

                    self.$bvToast.toast(error.message, {
                      title: 'Error',
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: false
                    });

                  })

            } else {
              this.$bvToast.toast("Please upload file to proceed", {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });
            }
          },
          handleVoucherRecipientFileSelected(){
            this.voucherRecipientFile = this.$refs.voucherRecipientData.files[0];
          },
          submitVoucherCodeVerification(){

            let self = this;

              if (this.voucher.customerId) {
                self.isLoading = true;

                self.sendVerificationCode({phoneNumber: self.customers[0].mobileNumber})
                    .then(function (response) {
                      self.isLoading = false;

                      if (response.statusCode == 200) {
                        self.$bvToast.toast("Verification code sent.", {
                          title: 'Success',
                          variant: 'success',
                          autoHideDelay: 5000,
                          appendToast: false
                        });
                        self.verificationCode = response.message;
                      }

                    })
                    .catch(function (error) {

                      self.isLoading = false;

                    });
              }
          },
          saveVoucher(){
            let self = this;

            if(self.voucher.paymentOption == "EPURSE") {
              if(!self.verificationCode) {
                self.submitVoucherCodeVerification();
              } else {
                if(self.verificationCode == self.customerVerificationCode) {
                  this.completePurchase();
                } else {
                  self.$bvToast.toast("Verification code mismatch", {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });
                }
              }
            } else {
              this.completePurchase();
            }
          },
          getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month, so add 1
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`
          },
          completePurchase() {
            let self = this;
            this.isLoading = true;

            if(this.voucher.buyingOption == "single") {
              this.voucher.voucherRecipients = []
              this.voucher.voucherRecipients.push({
                  name: this.voucher.customerId.name,
                  email: this.voucher.customerId.email,
                  phoneNumber: this.voucher.customerId.mobileNumber,
                  amount: this.voucher.amount
              })
            }

            this.voucher.customerId =  this.voucher.customerId.id;
            if(this.voucher.outletId && this.voucher.outletId.maximumVoucherParkingHours) {
              this.voucher.duration = this.voucher.outletId.maximumVoucherParkingHours * 60;
            } else {
              this.voucher.duration = 120
            }

            this.voucher.outletId = this.voucher.outletId.id;
            this.voucher.expiryDate = this.getCurrentDate();
            this.createParkingVoucher(this.voucher).then(() => {

              self.isLoading = false;

              self.$bvToast.toast("Voucher(s) generated successfully.", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false
              });

              if(self.role == "Merchant" || self.role == "Cashier"){
                self.$router.push("/apps/merchantVouchers");
              } else {
                self.$router.push("/apps/vouchers");
              }

            })
                .catch(error => {

                  self.isLoading = false;

                  self.$bvToast.toast(error.message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
          },
          searchCustomer(){
            let self = this;
            this.isLoading = true;

            this.searchCustomerByPhoneNumber({phone_number: this.voucher.phoneNumber})
                .then(function (customers) {
                  self.isLoading = false;
                  self.customers = customers;
                })
                .catch(function (error) {
                })
          },
          getVouchersByOutlet() {
            let self = this;
            this.isLoading = true;

            this.getOutletVoucherCodes({page: this.paginationVoucherCodes.current_page, fromDate:"all",toDate:"all", outletId:this.voucher.voucherOutletId, status: "NOT_UTILISED"})
                .then(function (outletVouchers) {
                  self.isLoading = false;
                  self.unutilisedVouchers = outletVouchers.content;

                })
                .catch(function (error) {
                  self.isLoading = false;

                })
          },
          getOutletVoucherCodesData() {
            let self = this;
            this.getOutletVoucherCodes({page: this.paginationVoucherCodes.current_page, fromDate:this.fromDateVoucherCode,toDate:this.toDateVoucherCode, outletId:this.voucherOutletId, status: this.voucherStatus})
                .then(function (outletVouchers) {
                  self.outletVouchers = outletVouchers.content;
                  self.paginationVoucherCodes.current_page = outletVouchers.number;
                  self.paginationVoucherCodes.total = outletVouchers.totalElements;
                  self.paginationVoucherCodes.per_page = outletVouchers.numberOfElements;
                  self.paginationVoucherCodes.from = outletVouchers.pageable.offset + 1 ;
                  self.paginationVoucherCodes.to = outletVouchers.pageable.pageSize;
                  self.paginationVoucherCodes.last_page = outletVouchers.totalPages;
                })
                .catch(function (error) {

                })
          },
          getOutletsData(){
              let self = this;

              this.getOutlets()
                  .then(function (outlets) {
                    self.outlets = outlets.filter(outlet => outlet.parkingVoucherAuthorization === "AUTHORIZED");

                  })
                  .catch(function (error) {
                    if( typeof error.response.status != "undefined"){
                      if(error.response.status == 401){
                        location.replace("/login");
                      }

                    }
                  })
            }
        },

        components:{
          Table,
          vSelect,
          CodeInput,
          FormWizard,
          TabContent,
          Loading,
          DatePicker
        }
    }
</script>

<style >

.vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  background-color: transparent !important;
}
.vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
  color:#4AB5E6 !important;
}
.vue-form-wizard .wizard-tab-content {
  padding-top: 50px;
}
.stepTitle{
  margin-top: -67px;
  color:#4AB5E6;
  font-weight: 600;
}
.vue-form-wizard.sm .wizard-icon-circle{
  height: 20px;
  width: 20px;
  background-color: #4AB5E6;
  border: none;
}
.vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
  top:14px;
  left:15.5%;
  width: 69.5% !important;
  background-color: #4AB5E6 !important;
  height: 2px !important;
}
table tr th{
  padding-top: 10px;
  padding-bottom: 10px;
}
fieldset.custom-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.custom-border {
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.vue-step-wizard{
  width: 100% !important;
}
.tabStatus{
  background-color: #002563 !important;
}
.tabLabel{
  font-weight: bolder;
}

label.form-label {
  font-size: 14px;
  font-weight: 700;
}

.vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
  width: 0% !important;
}
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
  background-color: #4AB5E6 !important;
  color:#000 !important;
  border: none;
  margin-top: 20px;

}

table td{
  padding: 10px;
}
</style>


